<template>
    <div>
        <h3 class="tab-title">{{ $translate('activity-schedule') }}</h3>
        <div class="schedule-options flex items-end justify-between">
            <div class="el-form">
                <label class="el-form__title">{{ device.name }}</label>
                <label class="toggle-check">
                    <input type="checkbox" name="toggle-check" :value="1" v-model="sheduleEditable" @input="dataChanged = true" />
                    <span class="toggle-check__body-wrapper">
                        <span class="toggle-check__body">
                            <span class="toggle-check__hendler"></span>
                        </span>
                        <span>{{ $translate('define-period-of-activation') }}</span>
                    </span>
                </label>
            </div>
            <div class="categories dropdown-wrapper">
                <Dropdown :options="['Repeat one off', 'Repeat Weekly']" :current="sheduleRepeat" name="Shedule" @change="($event)=> { sheduleRepeat = $event.target.value; dataChanged = true }" />
            </div>
        </div>
        <div class="schedule" style="margin-top: 35px;" :style="!sheduleEditable ? 'opacity: 0.7;pointer-events: none;' : ''">
            <!-- <div class="schedule__time-line">
                <div class="schedule__time-line-item">00:00am</div>
                <div class="schedule__time-line-item">06:00am</div>
                <div class="schedule__time-line-item">12:00am</div>
                <div class="schedule__time-line-item">06:00pm</div>
                <div class="schedule__time-line-item">12:00pm</div>
            </div> -->
            <div class="schedule__events-wrapper" style="max-width: 1176px;">
                <div class="schedule__calendar" style="background: #fff; border-radius: 7px 0 0 7px; z-index: 2;">
                    <div class="schedule__calendar-item">{{ $translate('mon') }}</div>
                    <div class="schedule__calendar-item">{{ $translate('tue') }}</div>
                    <div class="schedule__calendar-item">{{ $translate('wed') }}</div>
                    <div class="schedule__calendar-item">{{ $translate('thu') }}</div>
                    <div class="schedule__calendar-item">{{ $translate('fri') }}</div>
                    <div class="schedule__calendar-item">{{ $translate('sat') }}</div>
                    <div class="schedule__calendar-item">{{ $translate('sun') }}</div>
                </div>
                <div class="schedule__events" style="overflow: auto visible; margin-top: -35px; padding-top: 35px; padding-left: 24px; margin-left: -24px;">
                    <div style="position: relative;">
                        <div class="schedule__time-line" style="position: absolute; left: -24px; top: -35px; padding-left: 0;overflow: initial;">
                            <div class="schedule__time-line-item">00:00am</div>
                            <div class="schedule__time-line-item">06:00am</div>
                            <div class="schedule__time-line-item">12:00am</div>
                            <div class="schedule__time-line-item" style="flex: 0 0 232px;">06:00pm</div>
                            <div class="schedule__time-line-item" style="flex: 0 0 62px;">12:00pm</div>
                        </div>
                        <template v-for="([day, events]) in Object.entries(shedule)">
                            <div class="schedule__row" :key="day">
                                <div class="schedule__box" v-for="item in 24" :key="day + '-' +item" @click="createEvent(item, day)"></div>
                                <div
                                    class="schedule__event"
                                    v-for="s in events"
                                    :id="'event-' + s.id"
                                    :ref="'event-' + s.id"
                                    
                                    :key="s.id"
                                    :class="{ 'active': activeEvent && activeEvent.id == s.id }" @click="selectShedule(s)"
                                >
                                    <div class="schedule__event-inner">
                                        <div class="close" v-if="sheduleEditable" @click="removeEvent(s.id)">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                <line x1="6" y1="6" x2="18" y2="18" />
                                            </svg>
                                        </div>
                                        <div class="schedule__event-gap schedule__event-gap--left" id="resize-left"></div>
                                        <span class="user-select-none">{{ s.start | AmPmFilter }}</span>
                                        <span class="user-select-none">{{ s.end | AmPmFilter }}</span>
                                        <div class="schedule__event-gap schedule__event-gap--right" id="resize-right"></div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination wizard-actions fixed" v-show="dataChanged">
			<div class="pagination__body">
				<div class="pagination_right_btn_group">
					<button type="button" class="btn btn-primary btn-standart" @click="dataSave">
						Save
					</button> 
				</div>
			</div>
		</div>
    </div>
</template>
<script>

import { API, Storage } from 'aws-amplify';
import {
	updateDevice,
} from '@/graphql/mutations';

import Base from "@/components/base";

export default {
    props: ['device'],
    components: {
        ...Base
    },
    filters: {
        AmPmFilter(x) {
            let xInt = parseInt(x),
                xIntFormatted = xInt <= 12 ? xInt : xInt - 12,
                xString = xIntFormatted.toString().length < 2 ? `0${xIntFormatted}` : xIntFormatted.toString();

            return xString + (xInt <= 12 ? 'am' : 'pm')
        }
    },
    data: () => ({
        sheduleRepeat: 'Repeat Weekly',
        shedule: {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: [],
        },
        clearShedule: {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: [],
        },
        activeEvent: false,
        sheduleEditable: 0,
        dataChanged: false,
    }),
    methods: {
        selectShedule(event) {
            if (!this.sheduleEditable || this.activeEvent) return;

            this.activeEvent = event;
        },
        findEvent(id) {
            return Object.values(this.shedule).flat().find(el => el.id == id);
        },
        createEvent(cellIndex, day) {
            if (!this.sheduleEditable) return;
            this.dataChanged = true;

            let eventsArray = this.shedule[day];
            const event = {
                id: (Math.random() * 10000).toFixed(0),
                start: cellIndex - 1,
                end: cellIndex
            };
            eventsArray.push(event);

            this.$set(this.shedule, day, eventsArray);
            this.$nextTick(() => {
                this.сalcRectsByTime(event);

                const el = this.$refs[`event-${event.id}`]?.[0] || this.$refs[`event-${event.id}`];
                this.dragElement(el)
            });
        },
        removeEvent(id) {
            let [dayName, events] = Object.entries(this.shedule).find(([dayName, evArr]) => evArr.filter(event => event.id == id).length);
            events.splice(events.findIndex(event => event.id == id), 1);

            this.$set(this.shedule, dayName, events);

            this.dataChanged = true;
        },
        getCustomBoundings(searchingEvent, maxBoundings={ left: 0, right: 1080 }) {
            let [dayName, events] = Object.entries(this.shedule).find(([dayName, evArr]) => evArr.filter(event => event.id == searchingEvent.id).length);

            if (this.shedule[dayName].length <= 1) return false; 

            let eventElement = this.$refs[`event-${searchingEvent.id}`]?.[0] || this.$refs[`event-${searchingEvent.id}`],
                eventBoundLeft = parseInt(eventElement.style.left),
                eventBoundRight = parseInt(eventElement.style.left) + parseInt(eventElement.style.width),
                allEventsBoundings = this.shedule[dayName]
                    .filter(el => el.id != searchingEvent.id)
                    .map(event => this.$refs[`event-${event.id}`]?.[0] || this.$refs[`event-${event.id}`])
                    .map(el => ({
                        left: parseInt(el.style.left),
                        right: parseInt(el.style.left) + parseInt(el.style.width)
                    }))

            return {
                left: allEventsBoundings.reduce((acc, cur) => {
                    if (cur.right >= acc && cur.right <= eventBoundLeft) {
                        acc = cur.right; 
                    }

                    return acc
                }, maxBoundings.left),
                right: allEventsBoundings.reduce((acc, cur) => {
                    if (cur.left <= acc && cur.left >= eventBoundRight) {
                        acc = cur.left; 
                    }

                    return acc
                }, maxBoundings.right)
            }
        },
        dragElement(elmnt, offset=45, minChange=45, maxBoundings={ left: 0, right: 1080 }) {
            const $this = this;
            let pos1 = 0, pos3 = 0, resizeLeft = false, resizeRight = false, elId = null, eventId = null, eventObject = null, boundings = null;

            elmnt.onmousedown = dragMouseDown;

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();

                let lastEl = e.target;
                elId =  e.target.getAttribute('id')

                while (!elId || !elId.startsWith('event')) {
                    elId = lastEl.parentNode.getAttribute('id');
                    lastEl = lastEl.parentNode;
                };

                eventId = elId.split('-')[1];
                eventObject = $this.findEvent(eventId);

                if (e.target.getAttribute('id') == 'resize-right') {
                    resizeRight = true;
                } else if (e.target.getAttribute('id') == 'resize-left') {
                    resizeLeft = true;
                }

                pos3 = e.clientX;
                boundings = $this.getCustomBoundings(eventObject) || maxBoundings;

                if (resizeRight) {
                    document.onmousemove = elementResizeRight;
                    document.onmouseup = closeResizeElement;
                    return;
                }
                
                if (resizeLeft) {
                    document.onmousemove = elementResizeLeft;
                    document.onmouseup = closeResizeElement;
                    return;
                }

                document.onmouseup = closeDragElement;
                document.onmousemove = elementDrag;
            }

            function elementResizeLeft(e) {
                if (!$this.sheduleEditable) return;

                let width = parseInt(elmnt.style.width) + (pos3 - e.clientX),
                    widthChange = -(pos3 - e.clientX),
                    left = (parseInt(elmnt.style.left) + widthChange);

                elmnt.style.width = left <= boundings.left || (width <= offset / 1.5) ? elmnt.style.width : width + "px";
                elmnt.style.left = left <= boundings.left || (width <= offset / 1.5) ? elmnt.style.left : left + "px";
                pos3 = e.clientX;
            };

            function elementResizeRight(e) {
                if (!$this.sheduleEditable) return;
                let width = parseInt(elmnt.style.width) - (pos3 - e.clientX),
                    widthWithLeft = width + parseInt(elmnt.style.left);

                elmnt.style.width =widthWithLeft >= boundings.right ? elmnt.style.width : width + "px";;
                pos3 = e.clientX;
            };

            function closeResizeElement() {
                let width = parseInt(elmnt.style.width);

                if (width < minChange) {
                    $this.removeEvent(eventId);
                } else {
                    let part = width % minChange,
                        elWidth = part >= (minChange / 2) ? width + (minChange - part) : width - part;
                        
                    if (resizeLeft) {
                        elmnt.style.left = parseInt(elmnt.style.left) - (elWidth - parseInt(elmnt.style.width)) + "px";
                    }

                    elmnt.style.width = elWidth + "px";
                    $this.calcTimeByRects(eventObject);
                }
                
                resizeRight = false;
                resizeLeft = false;
                document.onmouseup = null;
                document.onmousemove = null;
                $this.dataChanged = true;
            };

            function elementDrag(e) {
                if (!$this.sheduleEditable) return;

                e = e || window.event;
                e.preventDefault();

                pos1 = pos3 - e.clientX;
                pos3 = e.clientX;

                let position = (elmnt.offsetLeft - pos1) < boundings.left 
                    ? boundings.left
                    : (elmnt.offsetLeft - pos1) + parseInt(elmnt.style.width) > boundings.right
                        ? boundings.right - parseInt(elmnt.style.width)
                        : (elmnt.offsetLeft - pos1);

                elmnt.style.left = position + "px";
            };

            function closeDragElement() {
                let left = parseInt(elmnt.style.left)
                let part = left % offset;
                let posLeft = part >= (offset / 2) ? left + (offset - part) : left - part;

                elmnt.style.left = posLeft + "px";

                $this.calcTimeByRects(eventObject);
                document.onmouseup = null;
                document.onmousemove = null;
                $this.dataChanged = true;
            };
        },
        сalcRectsByTime(event, hourWidth=45) {
            let [start, end] = [parseInt(event.start), parseInt(event.end)];

            const el = this.$refs[`event-${event.id}`]?.[0] || this.$refs[`event-${event.id}`]
            el.style.left = (start * hourWidth) + 'px';
            el.style.width = ((end - start) * hourWidth) + 'px';

            return event;
        },
        calcTimeByRects(event, hourWidth=45) {
            let element = this.$refs['event-' + event.id]?.[0] || this.$refs['event-' + event.id];
            let { left, width } = getComputedStyle(element);

            this.$set(event, 'start', (parseInt(left) / hourWidth));
            this.$set(event, 'end', ((parseInt(left) / hourWidth) + (parseInt(width) / hourWidth)));
        },
        setDarggable() {
            Object.values(this.shedule).flat()
                .map(ev => this.сalcRectsByTime(ev))
                .map(el => 'event-' + el.id)
                .map(ref => {
                    const el = this.$refs[ref]?.[0] || this.$refs[ref];

                    if (el) this.dragElement(el);
                });
        },
        async dataSave(){
            await API.graphql({
                query: updateDevice,
                variables: {
                    input: {
                        id: this.device.id,
                        shedule: JSON.stringify(this.shedule),
                        sheduleRepeat: this.sheduleRepeat,
                        periodOfActivation: this.sheduleEditable ? true : false,
                    },
                },
            }).then((res) => res.data.updateProduct)

            this.dataChanged = false;
        }
    },
    created(){
        if(this.device.shedule){
            this.shedule = JSON.parse(this.device.shedule);
        } else {
            this.shedule = JSON.parse(JSON.stringify(this.clearShedule));
        }
        
        if(this.device.periodOfActivation){
            this.sheduleEditable = 1;
        }
        
        if(this.device.sheduleRepeat){
            this.sheduleRepeat = this.device.sheduleRepeat;
        }
    },
    mounted() {
        this.setDarggable();
    },
}
</script>
<style scoped>
.schedule__event {
    overflow: hidden;
}
.schedule__event:hover .schedule__event-gap {
    display: block !important;
}
.schedule__event-inner {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.schedule__event-gap {
    display: none;
    background: #285265;
    opacity: 0.7;
    position: absolute;
    height: 44px;
    width: 5px;
    top: -12px;
    cursor: w-resize;
}
.schedule__event-gap--left {
    left: -14px;
}
.schedule__event-gap--right {
    right: -14px;
}

.user-select-none {
    user-select: none;
}

.schedule__event-inner .close {
    position: absolute;
    top: -10px;
    right: -8px;
    opacity: 0;
    transition: all .2s linear;
    cursor: pointer;
}

.schedule__event:hover .close{
    opacity: 1;
}
</style>