<template>
<div class="addition">
    <div class="headline-wrapper flex items-center">
        <div class="headline headline--sm flex-auto">
            <h2 class="headline__title">{{ $translate('dispatch-products') }}</h2>
            <p class="headline__text">{{ $translate('list-of-batches-and-products-attached-to-dispatch') }}</p>
        </div>
        <div class="buttons-group add-buttons">
            <button @click="$emit('openQrCode')" type="button" class="btn btn-w-shadow btn-standart-2">
                <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                <span>{{ $translate('qr-code') }}</span>
            </button>
        </div>
    </div>
    <ul class="editable-list">
        <li class="editable-list__item" v-for="b in batches" :key="b.id">
            <div class="data-row --inner">
                <div class="data-row__spoiler spoiler flex-auto --style-2">
                    <div class="spoiler__button flex items-center" @click="openOptions">
                        <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)" />
                        <div class="spoiler__title flex-auto"><span>{{ $translate('batch') }}:</span>{{ b.name }}</div>
                        <button type="button" class="btn btn-transp" data-target="qr-code">
                            <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                            <span>{{ $translate('qr-code') }}</span>
                        </button>
                    </div>
                    <div class="spoiler__body flex flex-col">
                        <div class="table-wrapper">
                            <table class="table style-2">
                                <thead>
                                    <tr>
                                        <th style="width: 60%">{{ $translate('product-name') }}</th>
                                        <th>{{ $translate('invoice-number') }}</th>
                                        <th>{{ $translate('po-number') }}</th>
                                        <th>{{ $translate('quantity&qnit') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="p in b.products">
                                        <tr v-if="p" :key="p.id">
                                            <td>
                                                <div class="module__check">
                                                    <a :href="`/products/${p.id}`" target="_blank" class="text" >{{p.title}}</a>
                                                </div>
                                            </td>
                                            <td>{{p.invoice_number||'-'}}</td>
                                            <td>{{p.purchase_order||'-'}}</td>
                                            <td>{{(p.quantity+p.units)||'-'}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>

</div>
</template>
<script>
import Popups from "@/components/popups";

export default {
    name:"BatсhesTab",
    props:["batches",'id'],
    components: {
        ...Popups,

    },
    data(){
        return{
        }
    },
    computed:{
     
    },
    methods: {
        openQrCode(){
            this.showQrCode=true
        },
        closeQrCode(){
            this.showQrCode=false
        },
        openOptions(ev) {            
            let parentElement = ev.target.closest(".spoiler__button");
            parentElement.classList.toggle("active");
            parentElement.nextElementSibling.classList.toggle("active");
        },
    }
}
</script>