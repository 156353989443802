<template>
<div>
    <!-- <div class="table-wrapper">
        <table class="table">
            <thead>
                <tr>
                    <th>
                        <div class="table-filter">
                            <div class="table-filter__options">
                                <button class="table-filter__btn">
                                    <span>{{ $translate('Alert Name') }}</span>
                                </button>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="table-filter">
                            <div class="table-filter__options">
                                <button class="table-filter__btn">
                                    <span>{{ $translate('date-and-time') }}</span>
                                </button>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="table-filter">
                            <div class="table-filter__options">
                                <button class="table-filter__btn">
                                    <span>{{ $translate('parameter') }}</span>
                                </button>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="table-filter">
                            <div class="table-filter__options">
                                <button class="table-filter__btn">
                                    <span>{{ $translate('behaviour') }}</span>
                                </button>
                            </div>
                        </div>
                    </th>
                    <th style="width: 55%">
                        <div class="table-filter flex items-center">
                            <div class="table-filter__options flex-auto">
                                <button class="table-filter__btn">
                                    <span> {{ $translate('description') }} </span>
                                </button>
                            </div>
                            <button class="btn btn-transp">
                                <inline-svg :src="require(`@/assets/img/Arrow-Rotate.svg`)" />
                            </button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="alertRow in alerts" :key="alertRow.id">
                    <td>{{ alertRow.name }}</td>
                    <td>{{ alertRow.datetime }}</td>
                    <td>Temperature</td>
                    <td>single value, +18</td>
                    <td>You should check the device!</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination fixed">
        <div class="pagination__body">
            <div class="pagination__show">
                <p>{{ $translate('rows-per-page') }}:</p>
                <div class="dropdown-wrapper">
                    <button class="dropdown-btn pagination__show-btn">
                        <span>10</span>
                        <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)" />
                    </button>
                    <div class="dropdown-content categories__dropdown">
                        <ul>
                            <li>
                                <span>10</span>
                            </li>
                            <li>
                                <span>20</span>
                            </li>
                            <li>
                                <span>30</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="pagination__list-wrapper">
                <a href="#" class="pagination__btn prev disabled">
                    <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                    <span>{{ $translate('prev') }}</span>
                </a>
                <ul class="pagination__list">
                    <li>
                        <a href="#" class="active">1</a>
                    </li>
                    <li>
                        <a href="#">2</a>
                    </li>
                    <li>
                        <a href="#">3</a>
                    </li>
                </ul>
                <a href="#" class="pagination__btn next">
                    <span>{{ $translate('next') }}</span>
                    <inline-svg :src="require(`@/assets/img/Arrow-next.svg`)" />
                </a>
            </div>
        </div>
    </div> -->

    <TableAdvanced 
        v-model="sortCol" 
        :rows="filteredRows" 
        :columns="columns" 
        :isCheckedAll="isCheckedAll"
        :checkedInputs="checkedInputs"
        @toggleAllCheckedInputs="toggleAllCheckedInputs"
        noDataMessage="All good! No alerts generated"
    >
        <template v-slot:filters></template>
        <template v-slot:row="slotData">
            <td>{{ slotData.r.name }}</td>
            <td>{{ new Date(slotData.r.datetime).toLocaleDateString() + ', ' + new Date(slotData.r.datetime).toLocaleTimeString() }}</td>
            <td>{{ slotData.r.ruleId && rules.find(findRule => findRule.id === slotData.r.ruleId) ? rules.find(findRule => findRule.id === slotData.r.ruleId).behavior.parameter : '-' }}</td>
            <td>{{ slotData.r.ruleId && rules.find(findRule => findRule.id === slotData.r.ruleId) ? `${rules.find(findRule => findRule.id === slotData.r.ruleId).behavior.behavior}, ${rules.find(findRule => findRule.id === slotData.r.ruleId).behavior.value}` : '-' }}</td>
            <td>{{ slotData.r.description.split('_').join(' ') }}</td>
        </template>
    </TableAdvanced>
    <Pagination v-model="filteredRows" :items="rawSource" />
</div>
</template>

<script>
import { mixDeleteItem, mixTables, mixArrayFields } from "@/mixins";
import Base from "@/components/base";

export default {
    name: 'DeviceAlertsTab',
    props: ['alerts', 'rules'],
    components: {
        ...Base,
    },
    mixins: [mixDeleteItem, mixTables, mixArrayFields],
    data() {
        return {
            source: "alerts",
            columns: [
                { name: this.$translate('Alert Name'), },
                { name: this.$translate('date-and-time'), },
                { name: this.$translate('parameter'), },
                { name: this.$translate('behaviour'), },
                { name: this.$translate('description'), },
            ],
            sortCol: "name",
        };
    },
    computed: {
        sourceData(){
            return this.alerts
        },
    }
}
</script>