<template>
<div class="addition">
    <div class="headline-wrapper flex items-center">
        <div class="headline headline--sm flex-auto">
            <h2 class="headline__title">{{ $translate('dispatch-conditions') }}</h2>
            <p class="headline__text">{{ $translate('list-of-conditions-attached-to-dispatch') }}</p>
        </div>
    </div>
    <ul class="editable-list">

        <li class="editable-list__item" v-for="c in conditions" :key="c.id">
            <div class="data-row" v-if="c.condition_type=='smart'">
                <ul class="data-row__list">
                    <li class="data-row__item">
                        <span class="data-row__icon">
                            <inline-svg :src="require(`@/assets/img/ico-signal.svg`)" />
                        </span>
                        {{ $translate('smart-condition') }}
                    </li>
                    <li class="data-row__item">
                        <span class="data-row__span">{{ $translate('batch') }}: </span>
                        {{c.batch?c.batch.name:'-'}}
                    </li>
                    <li class="data-row__item">
                        <span class="data-row__span">{{ $translate('device') }}: </span>
                        {{c.devices?c.devices.name:'-'}}
                    </li>
                </ul>
                <div class="data-row__controls">
                    <button type="button" class="btn btn-transp" @click="viewMore(c, 'conditionPopup')" style="padding: 0 5px;">{{ $translate('view-more') }}</button>
                </div>
            </div>
            <div class="data-row" v-if="c.condition_type=='business'">
                <ul class="data-row__list">
                    <li class="data-row__item">
                        <span class="data-row__icon">
                            <inline-svg :src="require(`@/assets/img/suitcase-portfolio.svg`)" />
                        </span>
                        {{ $translate('business-condition') }}
                    </li>
                    <li class="data-row__item">
                        <span class="data-row__span">{{ $translate('batch') }}: </span>
                        {{c.batch?c.batch.name:'-'}}
                    </li>
                </ul>
                <div class="data-row__controls">
                    <button type="button" class="btn btn-transp" @click="viewMore(c, 'businessConditionPopup')" style="padding: 0 5px;">{{ $translate('view-more') }}</button>
                </div>
            </div>
        </li>
       
    </ul>
        
    <ViewSmartCondition
        v-if="conditionPopup.show"
        :devices="devices"
        :condition="conditionPopup.condition"
        :batches="[]"
        @close="conditionPopup.show = false"
        :view="true"
        :deviceDataDuringSC="deviceDataDuringSC"
    />

    <ViewBusinessCondition 
        v-if="businessConditionPopup.show" 
        :condition="businessConditionPopup.condition"
        @close="businessConditionPopup.show = false"
        :batches="[]"
        :view="true"
    />
</div>
</template>
<script>
import AddSmartCondition from "@/components/popups/AddSmartCondition";
import AddBusinessCondition from "@/components/popups/AddBusinessCondition";

export default {
    name:"ConditionsTab",
    components: {
        ViewSmartCondition: AddSmartCondition,
        ViewBusinessCondition: AddBusinessCondition,
    },
    props:["conditions", "deviceDataDuringSC"],
    data(){
        return {
            conditionPopup: {
                show: false,
                condition: null,
            },
            businessConditionPopup: {
                show: false,
                condition: null,
            },
        }
    },
    computed: {
        devices() {
            return this.$store.state.devices || [];
        },
	},
    created(){
        console.log("from conditionsTab",this.conditions);
    },
    methods: {
        openOptions(ev) {            
                let parentElement=ev.target.closest(".spoiler__button")
                parentElement.classList.toggle("active");
                parentElement.nextElementSibling.classList.toggle("active");
        },
        viewMore(condition, type){
            this[type].condition = condition;
            this[type].show = true;
        },
    }
}
</script>