<template>
<div class="addition">
    <div class="headline-wrapper flex items-center">
        <div class="headline headline--sm flex-auto">
            <h2 class="headline__title">{{ $translate('dispatch-documents') }}</h2>
            <p class="headline__text">{{ $translate('list-of-documents-attached-to-dispatch') }}</p>
        </div>
    </div>
    <ul class="editable-list">
        <li class="editable-list__item" v-for="d in documents" :key="d.key">
            <div class="data-row">
                <ul class="data-row__list">
                    <li class="data-row__item">
                        <span class="data-row__icon">
                            <inline-svg :src="require(`@/assets/img/File-Pdf.svg`)" />
                        </span>
                        {{ d.title }}
                        <span style="margin-left: 15px;">Type of document: {{ (selectedTypeOptions.find(itemF => itemF.id === d.selectedType) ? selectedTypeOptions.find(itemF => itemF.id === d.selectedType).name : d.selectedType) || '-' }}</span>
                    </li>
                </ul>
                <div class="data-row__controls">
                    <a :href="d.url" target="_blank" class="btn btn-transp">{{ $translate('view-document') }}</a>
                </div>
            </div>
        </li>
    </ul>
</div>
</template>
<script>
export default {
    name:"DocumentsTab",
    props:["documents"],
    data() {
        return {
            selectedTypeOptions: [
                { id: 'sustainability rating', name: 'Sustainability rating' },
                { id: 'audits', name: 'Audits' },
                { id: 'absence of child labour', name: 'Absence of child labour' },
                { id: 'safety certification', name: 'Safety certification' },
                { id: 'non pollution', name: 'Non pollution' },
                { id: 'custom', name: 'Custom' },
            ]
        }
    },
    created(){
    },
    computed:{
        // documents(){
        //     return this.documentsSource||[]
        // }
    },
    methods: {
        
    }
}
</script>