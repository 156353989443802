<template>
<div>
    <div class="filter-options">
        <h2 class="filter-options__title flex-auto">{{ $translate('rules') }}<span>({{sourceData.length}})</span></h2>
        
        <div class="buttons-group add-buttons">
            <WhitePlusButton @click="addRulesOpen">{{ $translate('assign-to-an-existing-rule') }}</WhitePlusButton>
            <WhitePlusButton @click="addNewRuleOpen">{{ $translate('create-a-new-rule') }}</WhitePlusButton>
        </div>
        
    </div>

    <TableAdvanced 
        v-model="sortCol" 
        :rows="filteredRows" 
        :columns="columns" 
        :isCheckedAll="isCheckedAll"
        :checkedInputs="checkedInputs"
        @toggleAllCheckedInputs="toggleAllCheckedInputs"
    >
        <template v-slot:filters></template>
        <template v-slot:row="slotData">
            <td>
                <label class="module__check">
                    <input type="checkbox" name="privacy_policy" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
                    <span class="check"></span>
                    <span class="text">{{slotData.r.name}}</span>
                </label>
            </td>
            
            <td class="text-end">
                <div class="table-actions flex items-center justify-end">
                    <div class="toggle-check" @click="toggleRuleActive(slotData.r.id,slotData.r.status)">
                        <input type="checkbox" name="toggle-check" :checked="slotData.r.status==='enabled'"/>
                        <span class="toggle-check__body-wrapper" >
                            <span class="toggle-check__body">
                                <span class="toggle-check__hendler"></span>
                            </span>
                            <span class="text-check">{{ $translate('enabled') }}</span>
                            <span class="text-not-check">{{ $translate('disabled') }}</span>
                        </span>
                    </div>
                    <router-link :to="'/rules/'+slotData.r.id" class="btn btn-icon">
                        <inline-svg :src="require(`@/assets/img/ico-settings.svg`)" />
                    </router-link>
                    <!-- <button type="button" class="btn btn-icon red-color" @click="confirmDeleteOpen(getDeleteRelaionId(currentDevice.rules.items,'rulesID',slotData.r.id,'deviceID',id))"> -->
                    <button type="button" class="btn btn-icon red-color" @click="deletePopupInfo = { show: true, selectedRule: slotData.r.id }">
                        <inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
                    </button>
                </div>
            </td>
        </template>
    </TableAdvanced>

    <Pagination v-model="filteredRows" :items="rawSource" />

    <ConfirmAction 
        v-if="deletePopupInfo.show" 
        :title="$translate('delete-rule-from-this-device?')" 
        @close="confirmDeleteRuleClose" 
        @confirm="confirmDeleteRuleAction" 
        :processing="dProcessing"
    />
    <!-- before translate title="Delete rule from this device?"  -->
    <!-- <AddItems
        v-if="addRulesPopupInfo.show"
        :items="addRulesPopupInfo.rules"
        @close="addRulesClose"
        @submit="addRulesSubmit"
        :processing="aProcessing"
    >
        <template v-slot:firstColumn="slotData">
            <span class="text">
                {{ slotData.r.name }}
            </span>
        </template>
        <template v-slot:columns="slotData">
            <li class=data-row__item>
                {{ slotData.r.id }}
            </li>
        </template>
    </AddItems> -->

    <PopupWrpr @close="addRulesClose" v-if="addRulesPopupInfo.show">
        <div class="popup__window-content" style="max-width: 500px;">
            <div class="popup__window-header" style="margin-bottom: 20px;">
                <h2>{{ $translate('assign-to-an-existing-rule') }}</h2>
                <button type="button" class="popup__closer" @click="addRulesClose">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')"/>
                </button>
            </div>
            <div class="popup__window-body">
                <!-- <div class="items-group cards">
                    <div class="cards__col-4">
                        <form action="">
                            <input type="text" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
                        </form>
                    </div>
                </div> -->
                <a
                    v-for="ruleItem in addRulesPopupInfoRules" :key="ruleItem.id"
                    class="data-row existing_rule_button" @click="addRulesPopupInfo.selectedRule === ruleItem.id ? addRulesPopupInfo.selectedRule = null : addRulesPopupInfo.selectedRule = ruleItem.id"
                    :class="{ 'active':addRulesPopupInfo.selectedRule === ruleItem.id }"
                >
                    {{ ruleItem.name }}
                </a>
                <h3></h3>

                <PopupPagination v-model="addRulesPopupInfoRules" :items="addRulesPopupInfo.rules" />
            </div>
            <div class="popup__window-actions">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="addRulesClose">
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button type="button" class="btn btn-primary btn-standart-2" @click="addRulesSubmit" :class="[{aProcessing}, {'disabled':addRulesPopupInfo.selectedRule === null}]">
                    <span>{{ $translate('submit') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>

    <!-- <AlertSMS>

    </AlertSMS> -->

</div>
</template>


<script>
import { API } from "aws-amplify";
import { 
    updateRules,
    // createDeviceRules
    deleteRules,
} from "@/graphql/mutations";



import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";
import PopupWrpr from '@/components/popups/PopupWrpr';

import { mixDeleteItem,mixTables,mixArrayFields } from "@/mixins";
export default {
    name: "DeviceRulesTab",
    props: ["id","addNewRuleID"],
    components: {
        ...Base,
        ...Popups,
        ...Buttons,
        PopupWrpr,
    },
    mixins: [mixDeleteItem,mixTables,mixArrayFields],
    data() {
        return {
            // processing: false,
            source: "rules",
            columns: [
                { name: "Rule", filter: "name",class:"w-100" },
                { name: "Actions",class:"text-end" },
                // { name: "Product Name", filter: "title" },
                // { name: "RFID №", filter: "rfid_number" },
                // { name: "Serial", filter: "serial_number" },
                // { name: "Device", filter: "deviceName" },
                // { name: "Status", filter: "status" },
                // { name: "Manufacturer", filter: "test_is_calc_field" },
                // { name: "Manufacturing Date", filter: "manufacturing_date" },
            ],
            sortCol:"name",
            //delete config
            deleteQueryType: "deleteDeviceRules",
            arrayFields:{
				rules:[]
			},
            addRulesPopupInfo:{
                show: false,
                rules: null,
                selectedRule: null,
            },
			// active:null,

            // alert_action:{
            //     sms_email:false,
            //     dashboard:false,
            //     m2m:false,
            //     webhook:false,
                
            //     //dashboard
            //     display_name:false,
            //     note:null,

            //     mode:null,
            //     email:null,
            //     tel:null,

            //     deviceID:null,
            //     device_action:null,

            //     callback_url:null
            // },
            addRulesPopupInfoRules: [],
            deletePopupInfo: {
                show: false,
                selectedRule: null,
            }
        };
    },
    computed: {
        devices(){
            return this.$store.state.devices || []
        },
        rules(){
            return this.$store.state.rules || []
        },
        currentDevice(){
            return this.devices.find(d => {
                return d.id == this.id
            }) || {}
        },
        sourceData(){
            // if(!this.currentDevice.id) return []
            // let deviceRulesKeys=this.transformFieldToId(this.currentDevice.rules.items,'rulesID')
            // return this.rules.filter(r=>{
            //     return deviceRulesKeys.includes(r.id)
             
            // })||[]

            return this.rules.filter(item => item.deviceID === this.currentDevice.id);
		},
    },
    methods: {
        async toggleRuleActive(id,status){
            const rule = await API.graphql({
				query: updateRules,
                variables: {
                    input: {
                        id: id,
                        status: (status=="enabled"?"disabled":"enabled")
                    },
                },
			}).then(res => res.data.updateRules)
			this.$store.commit('UPDATE_ITEM', { field: 'rules', item: rule })
        },
        addRulesOpen() {
            // let currentDeviceRules = this.currentDevice.rules ? this.transformFieldToId(this.currentDevice.rules.items,"id") : [];
            this.addRulesPopupInfo.rules = this.rules.filter(item => item.deviceID === this.id);
            this.addRulesPopupInfo.show = true;
        },
        addRulesClose() {
            this.addRulesPopupInfo.rules = null;
            this.addRulesPopupInfo.show = false;
            this.addRulesPopupInfo.selectedRule = null;
        },
        async addRulesSubmit(ev) {
            console.log("submit event",ev);
            this.aProcessing = true;
            // const requests = ev.map((rulesID) =>
            //     API.graphql({
            //         query: createDeviceRules,
            //         variables: {
            //             input: {
            //                 deviceID: this.id,
            //                 rulesID:  rulesID  
            //             },
            //         },
            //     }).then((res) => res.data.createDeviceRules)
            // );
            // await Promise.all(requests);

            const rule = await API.graphql({
				query: updateRules,
                variables: {
                    input: {
                        id: this.addRulesPopupInfo.selectedRule,
                        deviceID: this.id
                    },
                },
			}).then(res => res.data.updateRules)
			this.$store.commit('UPDATE_ITEM', { field: 'rules', item: rule })

            this.refreshData();
            this.aProcessing = false;
            this.addRulesClose();
        },
        addNewRuleOpen(){
            this.$emit("addNewRuleOpen");
        },
        confirmDeleteRuleClose(){
            this.deletePopupInfo = {
                show: false,
                selectedRule: null,
            };
        },
        async confirmDeleteRuleAction(){
            this.aProcessing = true;
            await API.graphql({
				query: deleteRules,
                variables: {
                    input: {
                        id: this.deletePopupInfo.selectedRule
                    },
                },
			}).then(res => res.data.deleteRules)
            
            this.refreshData();
            this.aProcessing = false;
            this.confirmDeleteRuleClose();
        },
        // openAlertModal(alertModal){
        //     this.
        // },
        // closeAlertModal(){
        //     this.
        // }
    },
};
</script>

<style>
    .existing_rule_button {
        cursor: pointer;
    }
    .existing_rule_button:hover {
        background-color: var(--table-row-hover);
    }
    .existing_rule_button.active {
        background-color: var(--table-row-hover);
        font-weight: bold;
    }
</style>
