<template>
<div class="addition">
    <div class="headline-wrapper flex items-center">
        <div class="headline headline--sm flex-auto">
            <h2 class="headline__title">{{ $translate('sispatch-companies') }}</h2>
            <p class="headline__text">{{ $translate('list-of-companies-attached-to-despatch') }}</p>
        </div>
    </div>
    <ul class="editable-list" v-if="companies&&companies.length>0">
        <li class="editable-list__item" v-for="com in companies" :key="com.id">
            <div class="data-row">
                <ul class="data-row__list">
                    <li class="data-row__item">{{com.company_name}}</li>
                    <li class="data-row__item">
                        <span class="table-user flex items-center"><img :src="com.profile_photo_src" alt="" />{{com.user && com.user.fullname||'-'}}</span>
                    </li>
                </ul>
                <div class="data-row__controls">
                    <span class="table-statys enable"><span class="dot"></span>{{getCompanyStatus(com.id)}}</span>
                </div>
            </div>
        </li>
    </ul>
</div>
</template>
<script>
export default {
    name:"DispatchCompanyTab",
    props:["companies","companiesStatus",'globalStatus'],
    created(){

    },
    methods: {
        getCompanyStatus(id){
            let ownerId=this.companiesStatus.find(s=>s.type==='outcoming')?.id
            if(id===ownerId){
                return this.globalStatus
            }else{
                return this.companiesStatus.find(c=>c.id==id)?.status||'-'   
            }
        }
    }
}
</script>