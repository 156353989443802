import DeviceRulesTab from './DeviceRulesTab.vue'
import BatchesTab from './BatchesTab.vue'
import ConditionsTab from './ConditionsTab.vue'
import DispatchCompanyTab from './DispatchCompanyTab.vue'
import DocumentsTab from './DocumentsTab.vue'
import DeviceSheduleTab from './DeviceSheduleTab.vue'
import DeviceAlertsTab from './DeviceAlertsTab.vue'

export default {
    DeviceRulesTab,
    BatchesTab,
    ConditionsTab,
    DispatchCompanyTab,
    DocumentsTab,
    DeviceSheduleTab,
    DeviceAlertsTab,
}